import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useMemo, useState } from 'react';
import { RangeSlider, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "rangeslider"
    }}>{`RangeSlider`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { RangeSlider } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=3756%3A819'} storybook={'/?path=/story/inputs-rangeslider--rich-input'} vueStorybook={'/?path=/story/inputs-rangeslider--rich-input'} name={'RangeSlider'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`RangeSlider — компонент, который позволяет пользователю выбрать одно или несколько значений с линейной шкалы.
Например, можно использовать его для настройки громкости, прозрачности и т.п.
Также можно использовать его для выбора диапазонов, например интервала цен, или других значений.`}</p>
    <p>{`Рекомендуется при разработке продукта реализовывать мгновенную реакцию на взаимодействие пользователя со слайдером,
чтобы при передвижении ползунка сразу были видны изменения. Для этого используйте колбек `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`.`}</p>
    <p>{`Для настройки шкалы укажите значения `}<inlineCode parentName="p">{`min`}</inlineCode>{`, `}<inlineCode parentName="p">{`max`}</inlineCode>{` и `}<inlineCode parentName="p">{`step`}</inlineCode>{` (шаг шкалы).
Непрерывные шкалы на текущий момент не поддерживаются, поэтому всегда следует указывать подходящее для ситуации
значение `}<inlineCode parentName="p">{`step`}</inlineCode>{`.`}</p>
    <p>{`Компонент поддерживает управление с помощью клавиатуры. Сначала необходимо выбрать элемент с помощью клавиши `}<inlineCode parentName="p">{`Tab`}</inlineCode>{`.
Переключаться между ползунками можно с помощью клавиш `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` / `}<inlineCode parentName="p">{`Shift+Tab`}</inlineCode>{`.
Для изменения значения выбранного ползунка используйте клавиши со стрелками.`}</p>
    <p>{`Слайдер растягивается на 100% доступной ширины.
Чтобы ограничить ширину слайдера, оберните его в контейнер нужной ширины,
либо назначьте собственный css-класс с помощью `}<inlineCode parentName="p">{`className`}</inlineCode>{` и переопределите свойство `}<inlineCode parentName="p">{`width`}</inlineCode>{`.`}</p>
    <Playground __position={1} __code={'() => {\n  const [state, setState] = useState([\n    { id: \'1\', value: 1 },\n    { id: \'2\', value: 50 },\n  ])\n  return (\n    <RangeSlider\n      values={state}\n      onChange={setState}\n      fills={\'between\'}\n      min={1}\n      step={1}\n      max={100}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      RangeSlider,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [state, setState] = useState([{
          id: '1',
          value: 1
        }, {
          id: '2',
          value: 50
        }]);
        return <RangeSlider values={state} onChange={setState} fills={'between'} min={1} step={1} max={100} mdxType="RangeSlider" />;
      }}
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "количество-ползунков"
    }}>{`Количество ползунков`}</h3>
    <p>{`Количество ползунков зависит от количества элементов в массиве `}<inlineCode parentName="p">{`values`}</inlineCode>{`.
Можно использовать любое количество ползунков.
Идентификаторы всех ползунков должны быть уникальны.`}</p>
    <Playground __position={2} __code={'<RangeSlider\n  values={[\n    { id: \'1\', value: 0 },\n    { id: \'2\', value: 50 },\n    { id: \'3\', value: 100 },\n  ]}\n  min={0}\n  step={1}\n  max={100}\n/>\n<Space size={Size.small_3x} vertical horizontal />\n<RangeSlider\n  values={[\n    { id: \'1\', value: 0 },\n    { id: \'2\', value: 50 },\n  ]}\n  min={0}\n  step={1}\n  max={100}\n/>'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      RangeSlider,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <RangeSlider values={[{
        id: '1',
        value: 0
      }, {
        id: '2',
        value: 50
      }, {
        id: '3',
        value: 100
      }]} min={0} step={1} max={100} mdxType="RangeSlider" />
  <Space size={Size.small_3x} vertical horizontal mdxType="Space" />
  <RangeSlider values={[{
        id: '1',
        value: 0
      }, {
        id: '2',
        value: 50
      }]} min={0} step={1} max={100} mdxType="RangeSlider" />
    </Playground>
    <h3 {...{
      "id": "заполнение-трека"
    }}>{`Заполнение трека`}</h3>
    <p>{`Для управления заполнением трека предназначен проп `}<inlineCode parentName="p">{`fills`}</inlineCode>{`.`}</p>
    <p>{`Можно использовать одну из предзаданных конфигураций, передав строку в проп:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`'between'`}</inlineCode>{` - заполняет пространство между двумя крайними ползунками`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`'start'`}</inlineCode>{` - заполняет пространство от начала до первого ползунка`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`'end'`}</inlineCode>{` - заполняет пространство от последнего ползунка до конца`}</li>
    </ul>
    <p>{`Также можно передать массив отрезков для более точного контроля.`}</p>
    <p>{`Если параметр не передан, то по-умолчанию для трека с одним ползунком
выбирается значение `}<inlineCode parentName="p">{`start`}</inlineCode>{`, для трека с двумя ползунками - `}<inlineCode parentName="p">{`between`}</inlineCode>{`,
для остальных - заполнение не рисуется.`}</p>
    <Playground __position={3} __code={'() => {\n  const [state, setState] = useState([\n    { id: \'1\', value: 20 },\n    { id: \'2\', value: 80 },\n  ])\n  return (\n    <>\n      <RangeSlider\n        values={state}\n        onChange={setState}\n        min={0}\n        step={1}\n        max={100}\n        fills={\'none\'}\n      />\n      <RangeSlider\n        values={state}\n        onChange={setState}\n        min={0}\n        step={1}\n        max={100}\n        fills={\'between\'}\n      />\n      <RangeSlider\n        values={state}\n        onChange={setState}\n        min={0}\n        step={1}\n        max={100}\n        fills={\'start\'}\n      />\n      <RangeSlider\n        values={state}\n        onChange={setState}\n        min={0}\n        step={1}\n        max={100}\n        fills={\'end\'}\n      />\n      <RangeSlider\n        values={state}\n        onChange={setState}\n        min={0}\n        step={1}\n        max={100}\n        fills={\'none\'}\n      />\n      <RangeSlider\n        values={state}\n        onChange={setState}\n        min={0}\n        step={1}\n        max={100}\n        fills={[\n          {\n            from: 10,\n            to: state[0].value,\n          },\n        ]}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      RangeSlider,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [state, setState] = useState([{
          id: '1',
          value: 20
        }, {
          id: '2',
          value: 80
        }]);
        return <>
      <RangeSlider values={state} onChange={setState} min={0} step={1} max={100} fills={'none'} mdxType="RangeSlider" />
      <RangeSlider values={state} onChange={setState} min={0} step={1} max={100} fills={'between'} mdxType="RangeSlider" />
      <RangeSlider values={state} onChange={setState} min={0} step={1} max={100} fills={'start'} mdxType="RangeSlider" />
      <RangeSlider values={state} onChange={setState} min={0} step={1} max={100} fills={'end'} mdxType="RangeSlider" />
      <RangeSlider values={state} onChange={setState} min={0} step={1} max={100} fills={'none'} mdxType="RangeSlider" />
      <RangeSlider values={state} onChange={setState} min={0} step={1} max={100} fills={[{
            from: 10,
            to: state[0].value
          }]} mdxType="RangeSlider" />
    </>;
      }}
    </Playground>
    <h3 {...{
      "id": "отключенные-ползунки"
    }}>{`Отключенные ползунки`}</h3>
    <p>{`Если в объект value передать параметр `}<inlineCode parentName="p">{`disabled: true`}</inlineCode>{`, то можно зафиксировать ползунок на определенном месте.
Пользователь не сможет изменить значение ползунка.`}</p>
    <Playground __position={4} __code={'() => {\n  const [state, setState] = useState([\n    { id: \'1\', value: 1 },\n    { id: \'2\', value: 50, disabled: true },\n    { id: \'3\', value: 90 },\n  ])\n  return (\n    <RangeSlider\n      values={state}\n      onChange={setState}\n      min={1}\n      step={10}\n      max={100}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      RangeSlider,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [state, setState] = useState([{
          id: '1',
          value: 1
        }, {
          id: '2',
          value: 50,
          disabled: true
        }, {
          id: '3',
          value: 90
        }]);
        return <RangeSlider values={state} onChange={setState} min={1} step={10} max={100} mdxType="RangeSlider" />;
      }}
    </Playground>
    <h3 {...{
      "id": "подпись-значения"
    }}>{`Подпись значения`}</h3>
    <p>{`Ползункам можно задавать произвольную всплывающую подпись с помощью рендер-функции `}<inlineCode parentName="p">{`renderControlText`}</inlineCode>{`, либо через свойство `}<inlineCode parentName="p">{`label`}</inlineCode>{`.
Подпись отображается при ховере, фокусе, либо нажатии на ползунок.
Функция `}<inlineCode parentName="p">{`renderControlText`}</inlineCode>{` имеет приоритет перед свойством `}<inlineCode parentName="p">{`label`}</inlineCode>{`.`}</p>
    <p>{`Рендер-функция `}<inlineCode parentName="p">{`renderControlText`}</inlineCode>{` единственным аргументом принимает объект соответствующего значения из массива `}<inlineCode parentName="p">{`values`}</inlineCode>{`.
Из функции можно вернуть как строку, так и произвольный React-элемент.
Если требуется различное форматирование для разных ползунков, то их можно отличать с помощью свойства `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
    <p>{`Внутри функции `}<inlineCode parentName="p">{`renderControlText`}</inlineCode>{` можно выполнять преобразование значения, формируя таким образом нелинейные шкалы.`}</p>
    <Playground __position={5} __code={'() => {\n  const values = [1, 2, 5, 10, 15, 20, 25, 50, 75, 100]\n  const [state, setState] = useState([\n    { id: \'1\', value: 1 },\n    { id: \'2\', value: 5 },\n  ])\n  return (\n    <>\n      <RangeSlider\n        values={state}\n        onChange={setState}\n        step={1}\n        min={0}\n        max={values.length - 1}\n        renderControlText={v => values[v.value]}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      RangeSlider,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const values = [1, 2, 5, 10, 15, 20, 25, 50, 75, 100];
        const [state, setState] = useState([{
          id: '1',
          value: 1
        }, {
          id: '2',
          value: 5
        }]);
        return <>
      <RangeSlider values={state} onChange={setState} step={1} min={0} max={values.length - 1} renderControlText={v => values[v.value]} mdxType="RangeSlider" />
    </>;
      }}
    </Playground>
    <p>{`Отдельным ползункам также можно задавать произвольную всплывающую подпись с помощью свойства `}<inlineCode parentName="p">{`label`}</inlineCode>{`.`}</p>
    <p>{`При использовании такого варианта скорее всего потребуется реализовать зависимость между значением и подписью.
Для этого необходимо отработать событие `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` и выполнить соответствующее преобразование массива `}<inlineCode parentName="p">{`values`}</inlineCode>{`.`}</p>
    <p>{`Используйте такой вариант только в случае, если требуемого функционала не удаётся достичь с помощью `}<inlineCode parentName="p">{`renderControlText`}</inlineCode>{`.`}</p>
    <Playground __position={6} __code={'() => {\n  const formatLabel = value => `--${value}--`\n  const transformValues = values =>\n    values.map(v => ({ ...v, label: formatLabel(v.value) }))\n  const [state, setState] = useState(\n    transformValues([\n      { id: \'1\', value: 1 },\n      { id: \'2\', value: 50 },\n    ]),\n  )\n  const handleChange = values => setState(transformValues(values))\n  return (\n    <>\n      <RangeSlider\n        values={state}\n        onChange={handleChange}\n        step={1}\n        min={1}\n        max={100}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      RangeSlider,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const formatLabel = value => `--${value}--`;

        const transformValues = values => values.map(v => ({ ...v,
          label: formatLabel(v.value)
        }));

        const [state, setState] = useState(transformValues([{
          id: '1',
          value: 1
        }, {
          id: '2',
          value: 50
        }]));

        const handleChange = values => setState(transformValues(values));

        return <>
      <RangeSlider values={state} onChange={handleChange} step={1} min={1} max={100} mdxType="RangeSlider" />
    </>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={RangeSlider} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      